import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./sonnet.scss"
const SonnetPageTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { title, date, limericked, path } = frontmatter
  const sonnetNumber = parseInt(path.replace("/", ""), 10)
  //this is all very goofy nonsense based on very specific
  //expected document structure but whatever.
  let formatted = html
    .split("\n")
    .map(line => {
      if (/.+<\/div>/.test(line)) {
        const split = line.split("<")
        return `<span class="SonnetPage--line">${split[0]}</span><${split[1]}`
      }
      if (/<div\sclass.+>/.test(line)) {
        const split = line.split(">")
        return `${split[0]}><span class="SonnetPage--line">${split[1]}</span>`
      }
      if (line.indexOf("/div") > 0) {
        return line
      }
      return `<span class="SonnetPage--line">${line}</span>`
    })
    .join("")

  if (!limericked) {
    formatted = formatted.replace(
      'SonnetPage--limerick">',
      `SonnetPage--limerick --blank">This sonnet will be limericked on ${date}. Come back then!`
    ) + '</div>'
  }

  return (
    <Layout>
      <SEO title={title} />
      <h1 className="SonnetPage--title">{title}</h1>
      <div
        className="SonnetPage--content"
        dangerouslySetInnerHTML={{ __html: formatted }}
      ></div>
      <div className="SonnetPage--links">
        <span className="SonnetPage--back-link">
          {path !== "/1" && (
            <Link to={`/${sonnetNumber - 1}`}>Sonnet {sonnetNumber - 1}</Link>
          )}
        </span>

        <span className="SonnetPage--forward-link">
          {path !== "/154" && (
            <Link to={`/${sonnetNumber + 1}`}>Sonnet {sonnetNumber + 1}</Link>
          )}
        </span>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        limericked
      }
    }
  }
`

export default SonnetPageTemplate
